export const accent = '#f90000';

export const mainLeft = '#FFDE00';
export const mainCenter = '#7AD963';
export const mainRight = '#D9C326';

export const textDark = '#01295F';
export const greenDark = '#24B600';
export const lightAccent = '#F8FFDA';

export const subtle = '#767676';
export const lightSubtle = '#EEEEEE';
