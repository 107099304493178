import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BP from '../../constants/breakpoints';

const Box = ({ children, cols }) => (
  <Container cols={cols}>
    <div>{children}</div>
  </Container>
);

export const Container = styled.div`
  position: relative;
  max-width: 1370px;
  margin: 0 auto;
  padding: 8rem 2rem 0;
  ${props =>
    props.cols &&
    css`
      & > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-left: -10rem;
        & > * {
          margin-left: 10rem;
        }
        @media (max-width: ${BP.DESKTOP}px) {
          margin-left: -8rem;
          & > * {
            margin-left: 8rem;
          }
        }
        @media (max-width: ${BP.TABLET}px) {
          margin-left: -5rem;
          & > * {
            margin-left: 5rem;
          }
        }
      }
    `}
`;

Box.propTypes = {
  children: PropTypes.node.isRequired,
  cols: PropTypes.bool,
};

export default Box;
